
import gQrCode from "@/components/g-qrcode.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import { Toast } from "vant";
import gImage from "@/components/g-image.vue";
import EventBus from "@/assets/js/EventBus";
import { guideServer } from "@/assets/js/Request";

@Component({
  components: {
    gQrCode,
    gImage,
  },
})
export default class SGDetail extends Vue {
  @Prop({ type: String, default: "" }) private pid!: string;
  private isCodeVisible = false;
  private form = {
    pid: "",
    color_id: -1,
    type: 1, // 暂时固定为 1
  };
  private currentColorIndex = 0;
  private colorShow = false;
  private isCollect = false;
  private updateTag = false; // 更新标志
  private brandIntro = [
    {
      name: "新实木",
      type: "柜体品牌",
      level: "E0级",
      slogan: "新实木板，幸福美满",
      material: "实木颗粒板/全桉多层板",
      info: "国民环保优质家居材料，追求幸福美满的生活。采用综合符合“井”型压合，抗弯曲多用途。取材澳大利亚国树尤加利，甄选优质天然原木旋切成片。新型E0环保胶水强力压合抗变形。专用于板材的精密除醛设备，抽离板内有害游离成分，呼吸原木清香。",
    },
    {
      name: "大雪杉",
      type: "柜体品牌",
      level: "E0级",
      slogan: "大雪杉，不怕山高路远。世上无难事，只要肯登攀！",
      material: "OSB板",
      info: "与奋斗者艰苦奋斗，用于登攀的精神形成共鸣。大雪杉源取国木香杉大径5A级芯材，杉木具有芳香、抑菌防虫、柔韧不变形、耐水耐腐等优良木材特质。特级饰面源于自然原木纹理的花色采集，经过冷压、热压、砂光、除醛等24道严苛工艺质检，只为崇尚品质超凡稳定性能。整板采用全方木料无缝拼接，实心程度高达99%以上。采制国内优质香杉大径成年原木，质地优良无豁边少结疤，A级原木选取、A级原木切片，整板定制择优而取。2次人工养生，确保产品源头基材天然更健康！",
    },
    {
      name: "北极熊",
      type: "门板品牌",
      level: "F4星",
      slogan: "生活需要安全感",
      material: "OSB板",
      info: "北极熊品牌向往这每一个幸福健康的家都有着充满安全感的家居环境。采用欧洲先进标准，超平整饰面抗弯曲。构建力学+美学的平衡。甄选优质天然原木原料，严格按照欧洲EN300标准、LYT1580-2010标准进行生产。定向刨花结构，特性增强正侧面握钉力。膨胀系数小、不变形、稳定性好，材质均匀，耐久性、防潮性能方面均优于普通实木颗粒板板。创研除醛装置及专利技术，严苛执行F4星环保标准达！",
    },
  ];

  private productInfo: any = {
    id: "",
    name: "",
    uid: 0,
    view_times: 0,
    img_url: "",
    color_serial: "",
    collect_times: 0,
    substrate_brand: "",
    env_level: "",
    substrate_type: "",
    substrate_info: "",
    create_time: "",
    catelogs: [],
    colors: [],
    is_collect: 0,
  };

  @State control: any;
  @State("socketId") uid: any;

  get shareUrl(): string {
    return `${location.href}?uid=${this.uid}`;
  }

  get currentPlan(): any {
    return this.productInfo.colors[this.currentColorIndex]
      ? this.productInfo.colors[this.currentColorIndex]
      : null;
  }

  get currentImg(): string {
    return this.currentPlan && this.currentPlan.img_url
      ? this.currentPlan.img_url
      : "";
  }

  get curBrandIntro(): any {
    return this.currentPlan
      ? this.brandIntro.find((intro: any) => {
          return intro.name == this.currentPlan.catelog_name;
        })
      : null;
  }

  get productTypes(): string {
    return this.productInfo.catelogs.join("、") || "暂无";
  }

  get colorList(): Array<any> {
    return this.productInfo.colors || [];
  }

  toggleColorList(isShow: boolean, isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("toggleColorList", isShow);
    }
    this.colorShow = isShow;
  }

  switchColor(index: number, isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("switchColor", index);
    }
    this.currentColorIndex = index;
  }

  goBackTop(): void {
    EventBus.$emit("goBackTop", this.updateTag);
    this.$socket?.dispatchEvent("goBackTop", this.updateTag);
  }

  handleMessage(): void {
    // 切换色卡
    EventBus.$on("switchColor", (data: any) => {
      this.switchColor(data, false);
    });

    EventBus.$on("toggleColorList", (data: any) => {
      this.toggleColorList(data, false);
    });

    EventBus.$on("addStar", () => {
      this.addStar(false);
    });
  }
  //详情页面数据渲染
  getProductDetail(): void {
    Toast.loading({
      message: "加载中",
      forbidClick: true,
      loadingType: "spinner",
      duration: 0,
    });

    this.$get(guideServer + "/shopping_guide/get_product_detail", {
      id: this.form.pid,
      uid: this.uid || 0,
    }).then((res: any) => {
      console.log(res)
      const data = res.data.data;
      console.log(data)
      if (data) {
        this.productInfo = data;
        // this.productInfo.colors.push({name: "green",age: 10})
        this.isCollect = Boolean(this.productInfo.is_collect);
      }
      Toast.clear();
    });
  }

  showCode(): void {
    this.isCodeVisible = true;
  }

  addStar(isReal = true): void {
    this.updateTag = !this.updateTag; // 偶数次，负负得正，则不用更新列表
    this.isCollect = !this.isCollect;
    if (isReal) {
      this.$post(guideServer + "/shopping_guide/collect_product", {
        uid: this.uid,
        product_id: this.form.pid,
        product_type: this.form.type,
      }).then((res: any) => {
        let data = res.data;
        this.$showToast(data.msg);
        this.isCollect
          ? this.productInfo.collect_times++
          : this.productInfo.collect_times--;
      });
      this.$socket?.dispatchEvent("addStar", this.form.pid);
    } else {
      this.isCollect
        ? this.productInfo.collect_times++
        : this.productInfo.collect_times--;
    }
  }

  mounted(): void {
    if (this.pid) {
      this.form.pid = this.pid;
    } else {
      this.form.pid = this.$route.params.pid;
      if (this.$socket) this.handleMessage();
    }
    this.getProductDetail();
  }
}
